import "onsenui/css/onsen-css-components.css";
import "onsenui/css/onsenui.css";
import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import Auth from "./Component/Screen/Auth/Auth";
import Home from "./Component/Screen/Home/Home";
import Login from "./Component/Screen/Login/Login";
import Payment from "./Component/Screen/Payment/Payment";
import Surveyors from "./Component/Screen/Surveyor/Surveyor";
import Vendor from "./Component/Screen/Vendor/Vendor";

// console.log(socket);
export default class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>

          <Route exact path="/">
            <Auth screen={Home} />
          </Route>

          <Route exact path="/surveyor">
            <Auth screen={Surveyors} />
          </Route>

          <Route exact path="/vendor">
            <Auth screen={Vendor} />
          </Route>

          <Route exact path="/payment">
            <Auth screen={Payment} />
          </Route>

          <Route path="/">
            <Auth screen={Home} />
          </Route>
        </Switch>
      </HashRouter>
    );
  }
}
