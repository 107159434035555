import React, { Component } from "react";
import { Card } from "react-onsenui";
import BackendService from "../../Backend/Backend";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.print";
import "./Table.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
const $ = require("jquery");

const backend = new BackendService();
$.DataTable = window.DataTable;

export default class Payment extends Component {
  state = {
    transaction_data: [],
    all_client: [],
    all_script: [],
    client_id: "",
    from_date: new Date(),
    to_date: new Date(),
    filter: false,
    brokerage: 0,
    total: 0,
    profit_loss: 0,
    admin_data: [],
  };

  componentDidMount() {
    this.loadcontent();
    $(this.table).DataTable().clear();
    $(this.table).DataTable().destroy();

    if (localStorage.getItem("login-admin") == true) {
      window.location = "/";
    }
    if (localStorage.getItem("admin_data")) {
      this.setState(
        {
          admin_data: JSON.parse(localStorage.getItem("admin_data")),
          loading: false,
        },
        () => this.load_payment()
      );
    }
  }

  load_payment() {
    var data = {
      token: localStorage.getItem("token-admin"),
      admin_id: this.state.admin_data.id,
      client_id: this.state.client_id,
      from_date: moment(this.state.from_date).format("YYYY-MM-DD 00:00:00"),
      to_date: moment(this.state.to_date).format("YYYY-MM-DD 23:59:59"),
    };

    backend.load_payment(data).then((r) => {
      console.log(r);
      if (r.error == "False") {
        this.setState({
          transaction_data: r.data,
          total: r.total,
        });
        $(this.table).DataTable().clear();
        $(this.table).DataTable().destroy();
        this.loadcontent();
      } else {
        alert(r.message);
      }
    });
  }

  chng_client(id) {
    if (id > 0) {
      this.setState({
        client_id: id,
      });
    }
  }

  loadcontent() {
    // this.calculatetotals()
    $(this.table).DataTable({
      dom: "lfrtip",
      data: this.state.transaction_data,
      columnDefs: [
        {
          targets: "_all",
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).css("padding", "4px");
            $(td).css("text-align", "center");
            $(td).css("color", "black");
            $(td).css("font-weight", "bold");
          },
        },
      ],
      columns: [
        {
          title: "Surveyor",
          data: null,
          render: function (data, type, row) {
            return (
              "<lable>" +
              row.surveyor_name +
              "<br/><span style='font-size:12px;color:gray;'>" +
              row.surveyor_mobile +
              "</span></lable>"
            );
          },
        },
        {
          title: "Vendor Details",
          data: null,
          render: function (data, type, row) {
            return (
              "<lable>" +
              row.vendor_name +
              "<br/><span style='font-size:12px;color:gray;'>" +
              row.vendor_mobile +
              "</span></lable>"
            );
          },
        },
        {
          title: "Vendor Business",
          data: null,
          render: function (data, type, row) {
            return (
              "<lable>" +
              row.vendor_firm_name +
              "<br/><span style='font-size:12px;color:gray;'>" +
              row.vendor_a_card_no +
              "</span></lable>"
            );
          },
        },
        {
          title: "Before Bal",
          data: null,
          render: function (data, type, row) {
            return (
              '<lable style="color:' +
              (row.prev_balance == 0 ? "green" : "red") +
              '">' +
              row.prev_balance +
              "</lable>"
            );
          },
        },
        {
          title: "Before Bal",
          data: null,
          render: function (data, type, row) {
            return (
              '<lable style="color:' +
              (row.amount == 0 ? "green" : "red") +
              '">' +
              row.amount +
              "</lable>"
            );
          },
        },
        {
          title: "After Bal",
          data: null,
          render: function (data, type, row) {
            return (
              '<lable style="color:' +
              (row.after_balance == 0 ? "green" : "red") +
              '">' +
              row.after_balance +
              "</lable>"
            );
          },
        },
        {
          title: "Date/Time",
          data: "date_created",
        },
      ],
      initComplete: function () {
        var btns = $(".dt-button");
        btns.addClass("btn fred btn-sm");
        btns.removeClass("dt-button");
      },
      paging: true,
      ordering: true,
      // order: [[11, "desc"]],
      searching: true,
      // "pageLength": 15,
      info: true,
      autoWidth: false,
      responsive: true,
    });
  }
  render() {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          padding: 5,
          height: "92%",
          background: "#f7f7f7",
        }}
      >
        <div style={{ flex: 6, padding: 5 }}>
          <Card
            style={{
              height: "100%",
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <div style={{ flex: 1, background: "#fff", display: "flex" }}>
              <div style={{ flex: 3, display: "flex", paddingLeft: 15 }}>
                <div
                  class="panel card-view"
                  style={{ width: "100%", borderRadius: 10 }}
                >
                  <div
                    class="panel-heading small-panel-heading relative"
                    style={{ backgroundColor: "#336b87", borderRadius: 10 }}
                  >
                    <div class="pull-left">
                      <h6 class="panel-title txt-light">Summary</h6>
                    </div>
                    <div class="clearfix"></div>
                    <div class="head-overlay"></div>
                  </div>
                  <div class="panel-wrapper collapse in">
                    <div class="panel-body row pa-0">
                      <div class="data-with-border " style={{ padding: 10 }}>
                        <div class="container-fluid">
                          <div class="row">
                            <div
                              class="col-xs-4 text-center pl-0 pr-0 data-wrap-left"
                              style={{
                                background: "#fff",
                                borderStyle: "solid",
                                borderColor: "#5494ff",
                              }}
                            >
                              <span
                                class="weight-500 uppercase-font  block"
                                style={{ color: "gray" }}
                              >
                                Total Payment
                              </span>
                              <span class="block counter">
                                <span
                                  class="counter-anim form-control"
                                  style={{
                                    color: "green",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    borderWidth: 0,
                                  }}
                                >
                                  {parseFloat(this.state.total).toFixed(2)}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ flex: 4, marginLeft: 10 }}>
                <div class="panel card-view" style={{ borderRadius: 10 }}>
                  <div
                    class="panel-heading small-panel-heading relative"
                    style={{ backgroundColor: "#336b87", borderRadius: 10 }}
                  >
                    <div class="pull-left">
                      <h6 class="panel-title txt-light">Filter</h6>
                    </div>
                    <div class="clearfix"></div>
                    <div class="head-overlay"></div>
                  </div>
                  <div class="panel-wrapper collapse in">
                    <div class="panel-body row pa-0">
                      <div class="data-with-border" style={{ padding: 10 }}>
                        <div class="container-fluid">
                          <div class="row">
                            <div
                              class="col-xs-4 text-center  data-wrap-left"
                              style={{
                                background: "#fff",
                                borderStyle: "solid",
                                borderColor: "#ed4c82",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <span class="weight-500 uppercase-font ````````` block">
                                From
                              </span>
                              <div
                                class="input-group date"
                                id="datetimepicker1"
                              >
                                <DatePicker
                                  className="form-control"
                                  selected={this.state.from_date}
                                  onChange={(date) =>
                                    this.setState({ from_date: date })
                                  }
                                  dateFormat="dd MMM yyyy"
                                />
                              </div>
                            </div>
                            <div
                              class="col-xs-4 text-center   data-wrap-right"
                              style={{
                                background: "#fff",
                                borderStyle: "solid",
                                borderColor: "#ed4c82",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <span class="weight-500 uppercase-font `````````  block">
                                To
                              </span>
                              <div
                                class="input-group date"
                                id="datetimepicker1"
                              >
                                <DatePicker
                                  className="form-control"
                                  selected={this.state.to_date}
                                  onChange={(date) =>
                                    this.setState({ to_date: date })
                                  }
                                  dateFormat="dd MMM yyyy"
                                />
                              </div>
                            </div>
                            <div
                              class="col-xs-4 text-center   data-wrap-right"
                              style={{
                                background: "#fff",
                                borderStyle: "solid",
                                borderColor: "#ed4c82",
                              }}
                            >
                              <span
                                class="weight-500 uppercase-font `````````  block"
                                style={{ color: "white" }}
                              >
                                '
                              </span>
                              <button
                                onClick={() => this.load_payment()}
                                type="submit"
                                class="btn mr-10"
                                style={{
                                  backgroundColor: "#336b87",
                                  color: "white",
                                  fontWeight: "bold",
                                  alignSelf: "center",
                                  borderRadius: 15,
                                  fontSize: 14,
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ flex: 7, overflowX: "hidden", overflowY: "scroll" }}>
              <table
                ref={(r) => (this.table = r)}
                className="table newtable table-responsive table-bordered table-hover "
              >
                {/* <tfoot>
                  <tr>
                    <th>#</th>
                    <th style={{ fontSize: 15 }}>Total</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th style={{ fontSize: 15 }}>
                      {parseFloat(this.state.profit_loss).toFixed(2)}
                    </th>
                    <th style={{ fontSize: 15 }}>
                      {parseFloat(this.state.brokerage).toFixed(2)}
                    </th>
                    <th style={{ fontSize: 15 }}>
                      {parseFloat(this.state.volume).toFixed(2)}
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </tfoot> */}
              </table>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
