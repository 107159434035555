import React, { Component } from "react";
import { Card } from "react-onsenui";
import BackendService from "../../Backend/Backend";
import "./User.scss";

const backend = new BackendService();

export default class BasicTab extends Component {
  state = {
    client_data: [],
    admin_data: [],
  };

  componentDidMount() {
    if (localStorage.getItem("login-admin") == true) {
      window.location = "/";
    }
    if (localStorage.getItem("admin_data")) {
      this.setState(
        {
          admin_data: JSON.parse(localStorage.getItem("admin_data")),
          loading: false,
        },
        () => this.load_surveyor_data()
      );
    }
  }

  load_surveyor_data() {
    if (this.props.selected_id == null || this.props.selected_id == 0) {
      alert("Invalid Surveyor. Please Reload Page.");
      window.location.reload();
    } else {
      var data = {
        token: localStorage.getItem("token-admin"),
        admin_id: this.state.admin_data.id,
        surveyor_id: this.props.selected_id,
      };

      backend.load_surveyor_data(data).then((r) => {
        if (r.error == "False") {
          this.setState({
            client_data: r.data,
          });
        } else {
          alert(r.message);
        }
      });
    }
  }

  chng_status() {
    if (this.state.client_data.id == null || this.state.client_data.id == 0) {
      alert("Invalid Surveyor. Please Reload Page.");
    } else {
      var data = {
        token: localStorage.getItem("token-admin"),
        admin_id: this.state.admin_data.id,
        surveyor_id: this.props.selected_id,
      };

      backend.chng_surveyor_status(data).then((r) => {
        if (r.error == "False") {
          this.load_surveyor_data();
          this.props.reload();
        } else {
          alert(r.message);
        }
      });
    }
  }

  render() {
    return (
      <div class="panel-wrapper collapse in">
        <div class="panel-body">
          <div class="row">
            <div class="col-sm-12 col-xs-12" style={{ position: "relative" }}>
              <div
                class="form-wrap"
                style={{ display: "flex", height: "50vh" }}
              >
                <div style={{ flex: 1, padding: 5 }}>
                  <form>
                    <Card style={{ display: "flex", paddingTop: 5 }}>
                      <div style={{ flex: 4 }}>
                        <div
                          style={{
                            fontWeight: "bold",
                            color: "#375E97",
                            fontSize: 20,
                          }}
                        >
                          <span style={{ color: "black" }}> Name : </span>
                          {this.state.client_data.name}
                        </div>
                        <div
                          style={{
                            fontWeight: "bold",
                            color: "#375E97",
                            fontSize: 20,
                          }}
                        >
                          <span style={{ color: "black" }}> Username : </span>{" "}
                          {this.state.client_data.username}
                        </div>
                        <div
                          style={{
                            fontWeight: "bold",
                            color: "#375E97",
                            fontSize: 20,
                          }}
                        >
                          <span style={{ color: "black" }}> Mobile No. : </span>{" "}
                          {this.state.client_data.mobile}
                        </div>

                        <div
                          style={{
                            fontWeight: "bold",
                            color: "#375E97",
                            fontSize: 20,
                          }}
                        >
                          <span style={{ color: "black" }}>Surveys : </span>{" "}
                          {this.state.client_data.nos_survey}1
                        </div>
                        {this.state.client_data.id == 1 ? null : (
                          <div style={{ display: "flex", marginTop: 20 }}>
                            <div>
                              <button
                                type="button"
                                style={{ fontWeight: "bold", fontSize: 14 }}
                                onClick={() => this.chng_status()}
                                class={
                                  this.state.client_data.status == 1
                                    ? "btn btn-sm btn-success btn-rounded "
                                    : "btn btn-sm btn-default btn-rounded"
                                }
                              >
                                Active
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </Card>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
