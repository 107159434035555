import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./NavBar.css";
import BackendService from "../../Backend/Backend";
import moment from "moment";
import logo from "../../Photos/logo.jpg";
var tab = null;

const backend = new BackendService();

class NavBar extends Component {
  state = {
    tab: 1,
    loading: true,
    admin_data: [],
  };

  componentDidMount() {
    if (localStorage.getItem("admin_data")) {
      this.setState({
        admin_data: JSON.parse(localStorage.getItem("admin_data")),
        loading: false,
      });
    }
  }

  logout() {
    var confirm = window.confirm("Are Your Sure Want To Logout ?");
    if (confirm) {
      localStorage.clear();
      this.props.history.push("/login");
    }
  }
  render() {
    if (window.location.hash == "#/user") {
      tab = 3;
    } else if (window.location.hash == "#/surveyor") {
      tab = 2;
    } else if (window.location.hash == "#/vendor") {
      tab = 4;
    } else if (window.location.hash == "#/payment") {
      tab = 5;
    } else {
      tab = 1;
    }
    return this.state.loading == true ? (
      "loading"
    ) : (
      <div
        style={{
          height: 70,
          display: "flex",
          borderBottomWidth: 0,
          borderStyle: "solid",
          borderBottomColor: "#00",
          zIndex: 999,
          backgroundColor: "#4d94ff",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: 5,
            color: "#fff",
            fontSize: 22,
            fontWeight: "bold",
            flexDirection: "column",
            wordWrap: "break-word",
            minWidth: 150,
            marginRight: 10,
            marginLeft: 10,
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Bhuj Nagar Palika
          </span>
          <span
            style={{
              fontSize: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // display: "none",
            }}
          >
            Survey Panel
          </span>
        </div>
        <div
          style={{
            flex: 5,
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            marginLeft: 20,
          }}
        >
          <div
            onClick={() => {
              this.props.history.push("/");
            }}
            // className={tab != 1 ? "select" : ""}
            style={{
              // flex: 1,
              color: "#fff",
              height: "90%",
              justifyContent: "center",
              fontWeight: "bold",
              alignItems: "center",
              display: "flex",
              marginRight: 10,
            }}
          >
            <a
              style={{
                color: "white",
                padding: 5,
                borderStyle: "solid",
                borderWidth: tab == 1 ? 3 : 0,
                borderRadius: 10,
                borderColor: tab == 1 ? "#fff" : null,
              }}
            >
              HOME
            </a>
          </div>
          <div
            onClick={() => {
              this.props.history.push("/surveyor");
            }}
            // className={tab != 2 ? "select" : ""}
            style={{
              // flex: 1,
              color: "#fff",
              height: "90%",
              justifyContent: "center",
              fontWeight: "bold",
              alignItems: "center",
              display: "flex",
              marginRight: 10,
            }}
          >
            <a
              style={{
                color: "white",
                paddingLeft: 5,
                paddingRight: 5,
                borderStyle: "solid",
                borderWidth: tab == 2 ? 3 : 0,
                borderRadius: 20,
                borderColor: tab == 2 ? "#fff" : null,
              }}
            >
              Surveyors
            </a>
          </div>

          <div
            onClick={() => {
              this.props.history.push("/vendor");
            }}
            // className={tab != 4 ? "select" : ""}
            style={{
              // flex: 1,
              color: "#fff",
              height: "90%",
              justifyContent: "center",
              fontWeight: "bold",
              alignItems: "center",
              display: "flex",
              marginRight: 10,
            }}
          >
            <a
              style={{
                color: "white",
                paddingLeft: 5,
                paddingRight: 5,
                borderStyle: "solid",
                borderWidth: tab == 4 ? 3 : 0,
                borderRadius: 20,
                borderColor: tab == 4 ? "#fff" : null,
              }}
            >
              Vendors
            </a>
          </div>

          <div
            onClick={() => {
              this.props.history.push("/payment");
            }}
            // className={tab != 2 ? "select" : ""}
            style={{
              // flex: 1,
              color: "#fff",
              height: "90%",
              justifyContent: "center",
              fontWeight: "bold",
              alignItems: "center",
              display: "flex",
            }}
          >
            <a
              style={{
                color: "white",
                paddingLeft: 5,
                paddingRight: 5,
                borderStyle: "solid",
                borderWidth: tab == 5 ? 3 : 0,
                borderRadius: 20,
                borderColor: tab == 5 ? "#fff" : null,
              }}
            >
              Payments
            </a>
          </div>
        </div>

        <div
          style={{
            flex: 1,
            borderStyle: "solid",
            borderBottomColor: "red",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            verticalAlign: "middle",
            height: "99%",
          }}
        >
          <ul
            class="nav navbar-right top-nav pull-right"
            style={{ verticalAlign: "middle" }}
          >
            <li
              class="dropdown auth-drp"
              style={{
                backgroundColor: "white",
                borderRadius: 20,
                padding: 3,
                verticalAlign: "middle",
              }}
            >
              <a
                href="#"
                class="dropdown-toggle pr-0"
                data-toggle="dropdown"
                style={{
                  // backgroundColor: "white",
                  verticalAlign: "middle",
                  padding: 0,
                  borderRadius: 20,
                }}
              >
                <img
                  style={{ height: 40, width: 40, verticalAlign: "middle" }}
                  src={logo}
                  alt="user_auth"
                  class="user-auth-img img-circle"
                />
                <span class="user-online-status"></span>
              </a>
              <ul
                class="dropdown-menu user-auth-dropdown"
                data-dropdown-in="flipInX"
                data-dropdown-out="flipOutX"
              >
                <li>
                  <a href="#">
                    <i
                      class="fa fa-user"
                      aria-hidden="true"
                      style={{ fontSize: 15 }}
                    ></i>
                    <span>{this.state.admin_data.username}</span>
                  </a>
                </li>

                <li
                  onClick={() => {
                    this.props.history.push("/profile");
                  }}
                >
                  <a>
                    <i
                      class="fa fa-id-card"
                      aria-hidden="true"
                      style={{ fontSize: 15 }}
                    ></i>
                    <span>Change Password</span>
                  </a>
                </li>
                <li class="divider"></li>
                <li onClick={() => this.logout()}>
                  <a href="#">
                    <i class="zmdi zmdi-power"></i>
                    <span>Log Out</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(NavBar);
