import React, { Component } from "react";

import NavBar from "./NavBar";

export default class Defult extends Component {
  render() {
    return (
      <div class="wrapper theme-1-active pimary-color-red">
        <NavBar />
        <div style={{ height: "100vh", flex: 1, display: "flex" }}>
          <this.props.cmp />
        </div>
      </div>
    );
  }
}
