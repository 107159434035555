import React, { Component } from "react";
import "./User.scss";
import BackendService from "../../Backend/Backend";

const backend = new BackendService();

export default class AddSurveyor extends Component {
  state = {
    name: "",
    username: "",
    password: "",
    confirm_password: "",
    admin_data: [],
    mobile: "",
  };

  componentDidMount() {
    if (localStorage.getItem("login-admin") == true) {
      window.location = "/";
    }
    if (localStorage.getItem("admin_data")) {
      this.setState({
        admin_data: JSON.parse(localStorage.getItem("admin_data")),
        loading: false,
      });
    }
  }

  add_user = () => {
    if (
      this.state.name == "" ||
      this.state.name == null ||
      this.state.name == undefined
    ) {
      alert("Enter Name");
    } else if (
      this.state.username == "" ||
      this.state.username == null ||
      this.state.username == undefined
    ) {
      alert("Enter Username");
    } else if (
      this.state.mobile == "" ||
      this.state.mobile == null ||
      this.state.mobile == undefined
    ) {
      alert("Enter Mobile Number");
    } else if (this.state.mobile.length != 10) {
      alert("Enter Mobile Number Propely With 10 Digit");
    } else if (
      this.state.password == "" ||
      this.state.password == null ||
      this.state.password == undefined
    ) {
      alert("Enter Password");
    } else if (this.state.password != "" && this.state.password.length < 3) {
      alert("Enter Password Atleast 4 Character");
    } else if (
      this.state.confirm_password == "" ||
      this.state.confirm_password == null ||
      this.state.confirm_password == undefined
    ) {
      alert("Enter Confirm Password");
    } else if (this.state.password != this.state.confirm_password) {
      alert("Password Does Not Match");
    } else {
      var data = {
        name: this.state.name,
        username: this.state.username,
        mobile: this.state.mobile,
        password: this.state.confirm_password,
        token: localStorage.getItem("token-admin"),
        admin_id: this.state.admin_data.id,
      };

      backend.add_surveyor(data).then((r) => {
        if (r.error == "False") {
          this.setState({
            name: "",
            username: "",
            mobile: "",
            password: "",
            confirm_password: "",
          });
          alert(r.message);
          this.props.reload();
        } else if (r.error == "404") {
          alert(r.message);
          localStorage.clear();
          window.location.reload();
        } else {
          alert(r.message);
        }
      });
    }
  };

  render() {
    return (
      <div>
        <div style={{ padding: 30, paddingTop: 17 }}>
          <div
            class="panel panel-default card-view"
            style={{ borderRadius: 10 }}
          >
            <div class="panel-heading">
              <div class="pull-left">
                <label
                  class="panel-title txt-dark"
                  style={{ fontSize: 20, fontWeight: "bold" }}
                >
                  Add Surveyor
                </label>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="panel-wrapper collapse in">
              <div
                class="panel-body"
                style={{
                  flex: 1,
                  overflowY: "scroll",
                  height: "75vh",
                }}
              >
                <div class="row">
                  <div
                    class="col-sm-12 col-xs-12"
                    style={{ position: "relative" }}
                  >
                    <div class="form-wrap" style={{ display: "flex" }}>
                      <div style={{ flex: 1, padding: 5 }}>
                        <form>
                          <div class="form-group">
                            <label
                              class="control-label mb-10"
                              for="exampleInputuname_1"
                            >
                              Name
                            </label>
                            <div class="input-group">
                              <div class="input-group-addon">
                                <i
                                  style={{ fontWeight: "bold" }}
                                  class="icon-user"
                                ></i>
                              </div>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputuname_1"
                                value={this.state.name}
                                onChange={(text) =>
                                  this.setState({ name: text.target.value })
                                }
                                placeholder="Name"
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <label
                              class="control-label mb-10"
                              for="exampleInputEmail_1"
                            >
                              Username
                            </label>
                            <div class="input-group">
                              <div class="input-group-addon">
                                <i style={{ fontWeight: "bold" }}>@</i>
                              </div>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail_1"
                                value={this.state.username}
                                onChange={(text) =>
                                  this.setState({ username: text.target.value })
                                }
                                placeholder="Username"
                              />
                            </div>
                          </div>

                          <div class="form-group">
                            <label
                              class="control-label mb-10"
                              for="exampleInputEmail_1"
                            >
                              Mobile No.
                            </label>
                            <div class="input-group">
                              <div class="input-group-addon">
                                <i style={{ fontWeight: "bold" }}>M</i>
                              </div>
                              <input
                                type="number"
                                class="form-control"
                                id="exampleInputEmail_1"
                                value={this.state.mobile}
                                onChange={(text) =>
                                  this.setState({ mobile: text.target.value })
                                }
                                placeholder="Mobile No."
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                      <div style={{ flex: 1, padding: 5 }}>
                        <form>
                          <div class="form-group">
                            <label
                              class="control-label mb-10"
                              for="exampleInputpwd_2"
                            >
                              Password
                            </label>
                            <div class="input-group">
                              <div class="input-group-addon">
                                <i
                                  style={{ fontWeight: "bold" }}
                                  class="icon-lock"
                                ></i>
                              </div>
                              <input
                                type="password"
                                class="form-control"
                                id="exampleInputpwd_2"
                                value={this.state.password}
                                onChange={(text) =>
                                  this.setState({ password: text.target.value })
                                }
                                placeholder="Password"
                              />
                            </div>
                          </div>

                          <div class="form-group">
                            <label
                              class="control-label mb-10"
                              for="exampleInputpwd_2"
                            >
                              Confirm Password
                            </label>
                            <div class="input-group">
                              <div class="input-group-addon">
                                <i
                                  style={{ fontWeight: "bold" }}
                                  class="icon-lock"
                                ></i>
                              </div>
                              <input
                                type="password"
                                class="form-control"
                                id="exampleInputpwd_2"
                                value={this.state.confirm_password}
                                onChange={(text) =>
                                  this.setState({
                                    confirm_password: text.target.value,
                                  })
                                }
                                placeholder="Confirm Password"
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <button
                      class="btn btn-success btn-anim"
                      onClick={() => this.add_user()}
                      style={{
                        display: "flex",
                        alignSelf: "center",
                        position: "absolute",
                        right: 20,
                        borderRadius: 7,
                        fontSize: 15,
                        fontWeight: "bold",
                      }}
                      type="button"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
