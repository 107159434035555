import React, { Component } from "react";
import { Card } from "react-onsenui";
import BackendService from "../../Backend/Backend";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.print";
import "./Table.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
const $ = require("jquery");

const backend = new BackendService();
$.DataTable = window.DataTable;

export default class EditHistory extends Component {
  state = {
    transaction_data: [],
    all_client: [],
    all_script: [],
    client_id: "",
    from_date: new Date(),
    to_date: new Date(),
    filter: false,
    brokerage: 0,
    total: 0,
    profit_loss: 0,
    admin_data: [],
  };

  componentDidMount() {
    this.loadcontent();
    $(this.table).DataTable().clear();
    $(this.table).DataTable().destroy();

    if (localStorage.getItem("login-admin") == true) {
      window.location = "/";
    }
    if (localStorage.getItem("admin_data")) {
      this.setState(
        {
          admin_data: JSON.parse(localStorage.getItem("admin_data")),
          loading: false,
        },
        () => this.load_payment()
      );
    }
  }

  load_payment() {
    var data = {
      token: localStorage.getItem("token-admin"),
      admin_id: this.state.admin_data.id,
      client_id: this.state.client_id,
      from_date: moment(this.state.from_date).format("YYYY-MM-DD 00:00:00"),
      to_date: moment(this.state.to_date).format("YYYY-MM-DD 23:59:59"),
    };

    backend.load_payment(data).then((r) => {
      console.log(r);
      if (r.error == "False") {
        this.setState({
          transaction_data: r.data,
          total: r.total,
        });
        $(this.table).DataTable().clear();
        $(this.table).DataTable().destroy();
        this.loadcontent();
      } else {
        alert(r.message);
      }
    });
  }

  chng_client(id) {
    if (id > 0) {
      this.setState({
        client_id: id,
      });
    }
  }

  loadcontent() {
    // this.calculatetotals()
    $(this.table).DataTable({
      dom: "lfrtip",
      data: this.state.transaction_data,
      columnDefs: [
        {
          targets: "_all",
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).css("padding", "4px");
            $(td).css("text-align", "center");
            $(td).css("color", "black");
            $(td).css("font-weight", "bold");
          },
        },
      ],
      columns: [
        {
          title: "Surveyor",
          data: null,
          render: function (data, type, row) {
            return (
              "<lable>" +
              row.surveyor_name +
              "<br/><span style='font-size:12px;color:gray;'>" +
              row.surveyor_mobile +
              "</span></lable>"
            );
          },
        },
        {
          title: "Vendor Details",
          data: null,
          render: function (data, type, row) {
            return (
              "<lable>" +
              row.vendor_name +
              "<br/><span style='font-size:12px;color:gray;'>" +
              row.vendor_mobile +
              "</span></lable>"
            );
          },
        },
        {
          title: "Vendor Business",
          data: null,
          render: function (data, type, row) {
            return (
              "<lable>" +
              row.vendor_firm_name +
              "<br/><span style='font-size:12px;color:gray;'>" +
              row.vendor_a_card_no +
              "</span></lable>"
            );
          },
        },
        {
          title: "Before Bal",
          data: null,
          render: function (data, type, row) {
            return (
              '<lable style="color:' +
              (row.prev_balance == 0 ? "green" : "red") +
              '">' +
              row.prev_balance +
              "</lable>"
            );
          },
        },
        {
          title: "Before Bal",
          data: null,
          render: function (data, type, row) {
            return (
              '<lable style="color:' +
              (row.amount == 0 ? "green" : "red") +
              '">' +
              row.amount +
              "</lable>"
            );
          },
        },
        {
          title: "After Bal",
          data: null,
          render: function (data, type, row) {
            return (
              '<lable style="color:' +
              (row.after_balance == 0 ? "green" : "red") +
              '">' +
              row.after_balance +
              "</lable>"
            );
          },
        },
        {
          title: "Date/Time",
          data: "date_created",
        },
      ],
      initComplete: function () {
        var btns = $(".dt-button");
        btns.addClass("btn fred btn-sm");
        btns.removeClass("dt-button");
      },
      paging: true,
      ordering: true,
      // order: [[11, "desc"]],
      searching: true,
      // "pageLength": 15,
      info: true,
      autoWidth: false,
      responsive: true,
    });
  }
  render() {
    return (
      <div
        style={{
          flex: 1,
          height: "50vh",
        }}
      >
        <div style={{ flex: 1, padding: 5 }}>
          <div style={{ flex: 1, overflowX: "hidden", overflowY: "scroll" }}>
            <table
              ref={(r) => (this.table = r)}
              className="table newtable table-responsive table-bordered table-hover "
            ></table>
          </div>
        </div>
      </div>
    );
  }
}
