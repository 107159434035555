import moment from "moment";
import React, { Component } from "react";
import { Card } from "react-onsenui";

export default class TableCard extends Component {
  render() {
    return (
      <div class="row">
        {/* <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"> */}
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
          <div
            class="panel panel-default card-view panel-refresh"
            style={{ borderRadius: 10 }}
          >
            <div class="refresh-container">
              <div class="la-anim-1"></div>
            </div>
            <div class="panel-heading">
              <div class="pull-left">
                <h6 class="panel-title txt-dark">Live Trade Report</h6>
              </div>
              <div class="pull-right">
                <div onClick={() => this.props.update()}>
                  <a href="#" class="pull-left inline-block refresh mr-15">
                    <i class="zmdi zmdi-replay"></i>
                  </a>
                </div>
                {/* <a href="#" class="pull-left inline-block full-screen mr-15">
                  <i class="zmdi zmdi-fullscreen"></i>
                </a> */}
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="panel-wrapper collapse in">
              <div class="panel-body row pa-0">
                <div class="table-wrap">
                  <div class="table-responsive" style={{ height: "60vh" }}>
                    <table class="table table-hover mb-0">
                      <thead>
                        <tr>
                          <th
                            style={{
                              backgroundColor: "#fff",
                              position: "sticky",
                              top: 0,
                            }}
                          >
                            Client
                          </th>
                          <th
                            style={{
                              backgroundColor: "#fff",
                              position: "sticky",
                              top: 0,
                            }}
                          >
                            Symbol
                          </th>
                          <th
                            style={{
                              backgroundColor: "#fff",
                              position: "sticky",
                              top: 0,
                            }}
                          >
                            Qty
                          </th>
                          <th
                            style={{
                              backgroundColor: "#fff",
                              position: "sticky",
                              top: 0,
                            }}
                          >
                            Buy/Sell
                          </th>
                          <th
                            style={{
                              backgroundColor: "#fff",
                              position: "sticky",
                              top: 0,
                            }}
                          >
                            Rate
                          </th>
                          <th
                            style={{
                              backgroundColor: "#fff",
                              position: "sticky",
                              top: 0,
                            }}
                          >
                            Type
                          </th>
                          <th
                            style={{
                              backgroundColor: "#fff",
                              position: "sticky",
                              top: 0,
                            }}
                          >
                            Profit/Loss
                          </th>
                          <th
                            style={{
                              backgroundColor: "#fff",
                              position: "sticky",
                              top: 0,
                            }}
                          >
                            Date
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        style={{
                          overflowY: "scroll",
                          overflow: "scroll",
                        }}
                      >
                        {this.props.data != []
                          ? this.props.data.map((i, t) => (
                              <tr>
                                <td>
                                  <span class="txt-dark weight-500">
                                    {i.customer}
                                  </span>
                                </td>
                                <td>
                                  {i.symbol + " "}
                                  <span
                                    style={{
                                      color: "gray",
                                      fontWeight: "bold",
                                      fontSize: 10,
                                    }}
                                  >
                                    {moment(i.expiry_date).format("DD-MMM")}
                                  </span>
                                </td>
                                <td>
                                  <span class="txt-dark">
                                    <span>{i.qty}</span>
                                  </span>
                                </td>
                                <td>
                                  <span
                                    class={
                                      i.buy_sale == 0
                                        ? "txt-success weight-500"
                                        : "txt-danger weight-500"
                                    }
                                    style={{
                                      color: i.buy_sale == 0 ? "#7caa2d" : "",
                                    }}
                                  >
                                    {i.buy_sale == 0 ? "Buy" : "Sell"}
                                  </span>
                                </td>
                                <td>
                                  <span class="txt-dark">
                                    <span>
                                      {parseFloat(i.base_rate).toFixed(2)}
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  <span class="txt-dark weight-500">
                                    {i.type}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    class={
                                      i.profit_loss >= 0
                                        ? "label label-success"
                                        : "label label-danger"
                                    }
                                    style={{
                                      fontSize: 13,
                                      fontWeight: "bold",
                                      backgroundColor:
                                        i.profit_loss >= 0 ? "#7caa2d" : "",
                                    }}
                                  >
                                    {i.profit_loss}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    class="label"
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {i.date_created}
                                  </span>
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              class="panel panel-default card-view panel-refresh"
              style={{
                height: "28vh",
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 10,
              }}
            >
              <div class="refresh-container">
                <div class="la-anim-1"></div>
              </div>
              <div
                class="panel-heading"
                style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 20 }}
              >
                <div class="pull-left">
                  <h6
                    class="panel-title txt-dark"
                    style={{ textDecoration: "underline" }}
                  >
                    TERMS & CONDITIONS
                  </h6>
                </div>
                <div class="pull-right">
                  <a href="#" class="pull-left inline-block refresh mr-15">
                    {/* <i class="zmdi zmdi-replay"></i> */}
                  </a>
                </div>
                <div class="clearfix"></div>
              </div>
              <div class="panel-wrapper collapse in">
                <div
                  class="panel-body"
                  style={{ paddingBottom: 0, padding: 5 }}
                >
                  {/* <Card style={{ marginTop: 0, padding: 5 }}> */}
                  <div style={{ color: "black", fontWeight: "bold" }}>
                    <span style={{ fontSize: 10 }}>
                      {"1) MCX 09:02 & NSE 09:17 BAJE SE START HOGA."}
                    </span>
                    <br />
                    <span style={{ fontSize: 10 }}>
                      {"2) CHAMKA TRADE KABHI BHI DELETE HO JAYEGA."}
                    </span>
                    <br />
                    <span style={{ fontSize: 10 }}>
                      {"3) PENDING HIGH/LOW OR BID/ASK K HISHAB SE RAHEGA."}
                    </span>
                    <br />
                    <span style={{ fontSize: 10 }}>
                      {
                        "4) TECHINICAL ISSUES K LIYE INSTANT HELPLINE NUMBER PE CALL KARNA RAHEGA."
                      }
                    </span>
                    <br />
                    <span
                      style={{
                        fontSize: 10,
                        fontWeight: "bolder",
                        textDecoration: "underline",
                      }}
                    >
                      {
                        "5) TRADE MIN. 25 MINUTES RAKHNA HAI. SHORT TIME PROFIT KA TRADE DELETED HO JAYEGA."
                      }
                    </span>
                  </div>
                  {/* </Card> */}
                </div>
              </div>
            </div>
            <div
              class="panel panel-default card-view panel-refresh"
              style={{ borderRadius: 10 }}
            >
              <div class="refresh-container">
                <div class="la-anim-1"></div>
              </div>
              <div class="panel-heading">
                <div class="pull-left">
                  <h6 class="panel-title txt-dark">
                    Auto Sq. Off Notification
                  </h6>
                </div>
                <div class="pull-right">
                  <a href="#" class="pull-left inline-block refresh mr-15">
                    <i class="zmdi zmdi-replay"></i>
                  </a>
                </div>
                <div class="clearfix"></div>
              </div>
              <div
                class="panel-wrapper collapse in"
                style={{ height: "30vh", overflowY: "scroll" }}
              >
                <div class="panel-body">
                  {this.props.sq_data != [] ||
                  this.props.sq_data != null ||
                  this.props.sq_data != undefined
                    ? this.props.sq_data.map((i, t) => {
                        return (
                          <Card style={{ marginTop: 0, background: "#F62A00" }}>
                            <div style={{ color: "#fff", fontWeight: "bold" }}>
                              {"Client : "}
                              {i.customer}
                            </div>
                            <div style={{ color: "#fff", fontWeight: "bold" }}>
                              Auto Sq.Off Hit {i.profitloss} Loss
                            </div>
                          </Card>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
