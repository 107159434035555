import React, { Component } from "react";
import { Card } from "react-onsenui";
import BackendService from "../../Backend/Backend";
import "./User.scss";

const backend = new BackendService();

export default class Step4 extends Component {
  state = {
    client_data: [],
    admin_data: [],
  };

  componentDidMount() {
    if (localStorage.getItem("login-admin") == true) {
      window.location = "/";
    }
    if (localStorage.getItem("admin_data")) {
      this.setState(
        {
          admin_data: JSON.parse(localStorage.getItem("admin_data")),
          loading: false,
        },
        () => this.load_vendor_data()
      );
    }
  }

  load_vendor_data() {
    if (this.props.selected_id == null || this.props.selected_id == 0) {
      alert("Invalid Vendor. Please Reload Page.");
      window.location.reload();
    } else {
      var data = {
        token: localStorage.getItem("token-admin"),
        admin_id: this.state.admin_data.id,
        vendor_id: this.props.selected_id,
      };

      backend.load_vendor_data(data).then((r) => {
        console.log(r, "venddor");
        if (r.error == "False") {
          this.setState({
            client_data: r.data,
          });
        } else {
          alert(r.message);
        }
      });
    }
  }

  render() {
    return (
      <div class="panel-wrapper collapse in">
        <div class="panel-body">
          <div class="row">
            <div class="col-sm-12 col-xs-12" style={{ position: "relative" }}>
              <div
                class="form-wrap"
                style={{ display: "flex", height: "60vh" }}
              >
                <Card style={{ display: "flex", width: "100%", padding: 0 }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <img
                      src={
                        "http://bnp.shreeramtech.com/api/segments/" +
                        this.state.client_data.image_name +
                        "?" +
                        new Date()
                      }
                    />

                    <img
                      src={
                        "http://bnp.shreeramtech.com/api/segments/" +
                        this.state.client_data.image_name_2 +
                        "?" +
                        new Date()
                      }
                    />
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
