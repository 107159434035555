import React, { Component } from "react";
import "./Login.scss";
import BackendService from "../../Backend/Backend";
import logo from "../../Photos/logo.jpg";
const backend = new BackendService();

export default class Login extends Component {
  state = {
    username: "",
    password: "",
    message: "",
    username_s: false,
    password_s: false,
  };

  logKey(e) {
    if (e.key === "Enter") {
      this.login();
    }
  }

  login = () => {
    if (
      this.state.username == "" ||
      this.state.username == null ||
      this.state.username == undefined
    ) {
      // alert("Wrong username")
      this.setState({ username_s: true });
    } else if (
      this.state.password == "" ||
      this.state.password == null ||
      this.state.password == undefined
    ) {
      // alert("Wrong password")
      this.setState({ password_s: true });
    } else {
      var data = {
        username: this.state.username,
        password: this.state.password,
      };
      backend.login_admin(data).then((r) => {
        console.log(r);
        if (r.error == "False") {
          localStorage.setItem("token-admin", r.token);
          localStorage.setItem("admin_data", JSON.stringify(r.data));
          localStorage.setItem("login-admin", "true");
          localStorage.setItem("IsLoggedIn", "1");
          window.location = "/";
        } else {
          alert(r.message);
        }
      });
    }
  };

  componentDidMount() {
    document.addEventListener("keypress", (e) => this.logKey(e));
    if (localStorage.getItem("login-admin") == true) {
      window.location = "/";
    }
  }

  render() {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#F5F5F5",
        }}
      >
        <div class="form__box" style={{ height: 350, width: 600 }}>
          <div class="form__left">
            <div class="form__padding">
              <img class="form__image" src={logo} />
            </div>
          </div>
          <div class="form__right">
            <div class="form__padding-right">
              <form>
                <h1 class="form__title">Admin Login</h1>

                <input
                  style={{
                    color: this.state.username_s ? "white" : "",
                    backgroundColor: this.state.username_s ? "#ff00008a" : "",
                  }}
                  value={this.state.username}
                  onChange={(text) =>
                    this.setState({ username: text.target.value })
                  }
                  class="form__password input"
                  type="text"
                  placeholder="Username"
                />
                <input
                  style={{
                    color: this.state.password_s ? "white" : "",
                    backgroundColor: this.state.password_s ? "#ff00008a" : "",
                  }}
                  value={this.state.password}
                  onChange={(text) =>
                    this.setState({ password: text.target.value })
                  }
                  class="form__password input"
                  type="password"
                  placeholder="*****"
                />
                <input
                  class="form__submit-btn input"
                  onClick={() => this.login()}
                  type="button"
                  value="Login"
                  style={{
                    color: "#fff",
                    fontWeight: "bold",
                    textAlign: "center",
                    backgroundColor: "#015c8d",
                    height: "80%",
                  }}
                />
              </form>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
