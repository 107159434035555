import React, { Component } from "react";
import { Card } from "react-onsenui";
import BackendService from "../../Backend/Backend";
import AddSurveyor from "./AddSurveyor";
import BasicTab from "./BasicTab";
import EditSurveyor from "./EditSurveyor";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

const backend = new BackendService();

export default class Vendor extends Component {
  state = {
    defult: 0,
    tab: 1,
    all_broker_data: [],
    vendor_data: [],
    vendor_data2: [],
    dealer_brokerange: [],
    searchvalue: "",
    selected_client: [],
    admin_data: [],
    active: 0,
    deactive: 0,
  };

  componentDidMount() {
    if (localStorage.getItem("login-admin") == true) {
      window.location = "/";
    }
    if (localStorage.getItem("admin_data")) {
      this.setState(
        {
          admin_data: JSON.parse(localStorage.getItem("admin_data")),
          loading: false,
        },
        () => this.load_data()
      );
    }
  }

  searchcustomer() {
    if (this.state.searchvalue.length > 0) {
      var data = this.state.vendor_data2.filter(
        (x) =>
          x.mobile
            .toLowerCase()
            .includes(this.state.searchvalue.toLowerCase()) ||
          x.a_card_no
            .toLowerCase()
            .includes(this.state.searchvalue.toLowerCase())
      );
      this.setState({ vendor_data: data });
    } else {
      this.setState({ vendor_data: this.state.vendor_data2 });
    }
  }

  load_data() {
    var data = {
      token: localStorage.getItem("token-admin"),
      admin_id: this.state.admin_data.id,
    };

    backend.load_vendors(data).then((r) => {
      if (r.error == "False") {
        this.setState({
          vendor_data: r.data,
          vendor_data2: r.data,
          active: r.active,
          deactive: r.deactive,
        });
      } else if (r.error == "404") {
        alert(r.message);
        localStorage.clear();
        window.location.reload();
      } else {
        alert(r.message);
      }
    });
  }

  render() {
    return (
      <div style={{ flex: 1, display: "flex" }}>
        <div style={{ flex: 2, background: "white" }}>
          <div style={{ background: "white", padding: 10, paddingRight: 5 }}>
            <Card
              style={{
                margin: 0,
                display: "flex",
                height: 45,
                padding: 10,
                paddingRight: 0,
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "-moz-initial",
              }}
            >
              <div style={{ flex: 1 }}>
                <div class="input-group" style={{ display: "unset" }}>
                  <input
                    style={{
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderBottomWidth: 0,
                      borderRightWidth: 0,
                      borderLeftWidth: 0,
                      flex: 1,
                    }}
                    onChange={(t) =>
                      this.setState({ searchvalue: t.target.value }, () =>
                        this.searchcustomer()
                      )
                    }
                    type="text"
                    class="form-control"
                    id="exampleInputuname_3"
                    placeholder="Search By Adhaar/Mobile No."
                  />
                </div>
              </div>
            </Card>
          </div>
          <div
            style={{
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
              height: "80vh",
            }}
          >
            {this.state.vendor_data != null ? (
              this.state.vendor_data.map((i, t) => (
                <Card
                  onClick={() =>
                    this.setState({ defult: 2, selected_client: i })
                  }
                  style={{
                    display: "flex",
                    paddingTop: 5,
                    backgroundColor: i.id == 1 ? "#d0e1f9" : "white",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 16,
                        color: "black",
                      }}
                    >
                      Name: {i.name}
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "black",
                        wordWrap: "break-word",
                      }}
                    >
                      Adhaar : {i.a_card_no}
                    </div>
                    <div
                      style={{
                        color: "#375E97",
                        wordWrap: "break-word",
                      }}
                    >
                      Firm : {i.firm_name}
                    </div>
                    <div
                      style={{
                        color: "black",
                      }}
                    >
                      Mob. : {i.mobile}
                    </div>
                    <div
                      style={{
                        color: i.balance >= 0 ? "green" : "red",
                        fontSize: 18,
                        fontWeight: "bold",
                      }}
                    >
                      Balance : {i.balance}
                    </div>
                    <div
                      style={{
                        color: "black",
                      }}
                    >
                      Steps :{" "}
                      <span
                        style={{
                          color: i.step_1 == "1" ? "green" : "red",
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        1
                      </span>{" "}
                      <span
                        style={{
                          color: i.step_2 == "1" ? "green" : "red",
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        2
                      </span>{" "}
                      <span
                        style={{
                          color: i.step_3 == "1" ? "green" : "red",
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        3
                      </span>{" "}
                      <span
                        style={{
                          color: i.step_4 == "1" ? "green" : "red",
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        4
                      </span>
                    </div>
                  </div>
                </Card>
              ))
            ) : (
              <div>No Data Found</div>
            )}
          </div>
        </div>
        <div style={{ flex: 5, background: "#D0E1F9" }}>
          {this.state.defult == 0 ? (
            <div
              style={{
                display: "flex",
                margin: 20,
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
                flex: 1,
                fontWeight: "bold",
                fontSize: 55,
                color: "#4d94ff",
                wordWrap: "break-word",
                flexDirection: "column",
              }}
            >
              <span> Welcome to Vendors Section</span>
              <div>
                Active :
                <span style={{ color: "green" }}>{this.state.active}</span>
              </div>
              <div>
                De-Active :{" "}
                <span style={{ color: "red" }}>{this.state.deactive}</span>
              </div>
            </div>
          ) : null}

          {this.state.defult == 2 ? (
            <div>
              <div style={{ padding: 30, paddingTop: 17 }}>
                <div class="panel panel-default card-view">
                  <div class="panel-heading">
                    <div class="panel-heading">
                      <div
                        class="panel-title txt-dark"
                        style={{
                          color: "#2A2922",
                          fontWeight: "bold",
                          background: "#F3EBDD",
                          borderRadius: 7,
                          width: "100%",
                          height: 40,
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          fontSize: 17,
                        }}
                      >
                        Name : {this.state.selected_client.name} (
                        {this.state.selected_client.firm_name}){" "}
                      </div>
                    </div>

                    <div class="segment" style={{ display: "flex", flex: 1 }}>
                      <div class="segment__item">
                        <input
                          type="radio"
                          class="segment__input"
                          style={{ margin: 0 }}
                          name="segment-a"
                          checked={this.state.tab == 1 ? true : false}
                          onClick={() => this.setState({ tab: 1 })}
                        />
                        <div class="segment__button">Step 1</div>
                      </div>
                      <div class="segment__item">
                        <input
                          type="radio"
                          class="segment__input"
                          style={{ margin: 0 }}
                          name="segment-a"
                          checked={this.state.tab == 2 ? true : false}
                          onClick={() => this.setState({ tab: 2 })}
                        />
                        <div
                          class="segment__button"
                          style={{
                            color:
                              this.state.selected_client.step_2 == 0
                                ? "red"
                                : "",
                          }}
                        >
                          Step 2
                        </div>
                      </div>
                      <div class="segment__item">
                        <input
                          type="radio"
                          class="segment__input"
                          style={{ margin: 0 }}
                          name="segment-a"
                          checked={this.state.tab == 3 ? true : false}
                          onClick={() => this.setState({ tab: 3 })}
                        />
                        <div
                          class="segment__button"
                          style={{
                            color:
                              this.state.selected_client.step_3 == 0
                                ? "red"
                                : "",
                          }}
                        >
                          Step 3
                        </div>
                      </div>
                      <div class="segment__item">
                        <input
                          type="radio"
                          class="segment__input"
                          style={{ margin: 0 }}
                          name="segment-a"
                          checked={this.state.tab == 4 ? true : false}
                          onClick={() => this.setState({ tab: 4 })}
                        />
                        <div
                          class="segment__button"
                          style={{
                            color:
                              this.state.selected_client.step_4 == 0
                                ? "red"
                                : "",
                          }}
                        >
                          Step 4
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>

                  {this.state.tab == 1 ? (
                    <BasicTab
                      reload={() => {
                        this.load_data();
                        this.setState({ defult: 2 });
                      }}
                      key={this.state.selected_client.id}
                      selected_id={
                        this.state.selected_client != null
                          ? this.state.selected_client.id
                          : "No Client"
                      }
                    />
                  ) : null}

                  {this.state.tab == 2 ? (
                    <Step2
                      reload={() => {
                        this.load_data();
                        this.setState({ defult: 2 });
                      }}
                      key={this.state.selected_client.id}
                      selected_id={
                        this.state.selected_client != null
                          ? this.state.selected_client.id
                          : "No Client"
                      }
                    />
                  ) : null}
                  {this.state.tab == 3 ? (
                    <Step3
                      reload={() => {
                        this.load_data();
                        this.setState({ defult: 2 });
                      }}
                      key={this.state.selected_client.id}
                      selected_id={
                        this.state.selected_client != null
                          ? this.state.selected_client.id
                          : "No Client"
                      }
                    />
                  ) : null}
                  {this.state.tab == 4 ? (
                    <Step4
                      reload={() => {
                        this.load_data();
                        this.setState({ defult: 2 });
                      }}
                      key={this.state.selected_client.id}
                      selected_id={
                        this.state.selected_client != null
                          ? this.state.selected_client.id
                          : "No Client"
                      }
                    />
                  ) : null}
                  {/* 
                  {this.state.tab == 3 ? (
                    <LedgerTab
                      reload={() => this.load_data()}
                      selected_client_id={
                        this.state.selected_client != null
                          ? this.state.selected_client.id
                          : "No Client"
                      }
                    />
                  ) : null}

                  {this.state.tab == 4 ? (
                    <RejectLog
                      reload={() => this.load_data()}
                      selected_client_id={
                        this.state.selected_client != null
                          ? this.state.selected_client.id
                          : "No Client"
                      }
                    />
                  ) : null}

                  {this.state.tab == 5 ? (
                    <LoginLog
                      reload={() => this.load_data()}
                      selected_client_id={
                        this.state.selected_client != null
                          ? this.state.selected_client.id
                          : "No Client"
                      }
                    />
                  ) : null}

                  {this.state.tab == 6 ? (
                    <UserScript
                      reload={() => this.load_data()}
                      selected_client_id={
                        this.state.selected_client != null
                          ? this.state.selected_client.id
                          : "No Client"
                      }
                    />
                  ) : null} */}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
