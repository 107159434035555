import React from 'react'
import { Redirect } from 'react-router-dom'
import Defult from '../Layout/Defult'

export default function Auth(props) {
    const Cmp = props.screen;

    return (
        (localStorage.getItem("IsLoggedIn") == "1" ) ?  <Defult cmp={Cmp}/>  : <Redirect to="login" /> 
    )
}