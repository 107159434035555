import React, { Component } from "react";
import "./home.css";
import BackendService from "../../Backend/Backend";
import moment from "moment";
import TableCard from "./TableCard";
import survey from "../../Photos/survey.png";
import user from "../../Photos/user.png";
import payment from "../../Photos/payment.png";

const backend = new BackendService();

export default class Home extends Component {
  state = {
    dashboard: [],
    transaction_data: [],
    sq_data: [],
    from_date: new Date(),
    to_date: new Date(),
  };

  componentDidMount() {
    // this.load_home();
  }

  load_home() {
    var data = {
      token: localStorage.getItem("token-dealer"),
      dealer_id: localStorage.getItem("id-dealer"),
      server_code: localStorage.getItem("server-dealer"),
      week_from: moment(this.state.from_date)
        .startOf("isoWeek")
        .format("YYYY-MM-DD 00:00:00"),
      week_to: moment(this.state.to_date)
        .endOf("isoWeek")
        .format("YYYY-MM-DD 23:59:59"),
      from_date: moment().format("YYYY-MM-DD 00:00:00"),
      to_date: moment().format("YYYY-MM-DD 23:59:59"),
    };

    backend.load_home(data).then((r) => {
      console.log(r);
      if (r.error == "False") {
        this.setState({
          dashboard: r.data,
          transaction_data: r.transaction,
          sq_data: r.sq_data,
        });
      } else {
        alert(r.message);
      }
    });
  }

  logout = () => {
    localStorage.clear();
    window.location = "/#/login";
  };
  render() {
    return (
      <div style={{ background: "#D0E1F9", width: "100%" }}>
        <div
          class="row"
          style={{
            display: "flex",
            justifyContent: "space-around",
            background: "#D0E1F9",
            paddingTop: 20,
            flex: 1,
          }}
        >
          <div
            class="panel panel-default card-view pa-0 col-lg-6 col-md-6 col-sm-6 col-xs-12"
            style={{ borderRadius: 10, marginLeft: "5%" }}
          >
            <div class="panel-wrapper collapse in">
              <div class="panel-body pa-0">
                <div class="sm-data-box " style={{ borderRadius: 10 }}>
                  <div class="container-fluid">
                    <div class="row">
                      <div
                        class="col-xs-6 text-center pl-0 pr-0 data-wrap-left"
                        style={{ background: "#4D85BD", borderRadius: 10 }}
                      >
                        <span class="txt-light block counter">
                          <span class="counter-anim">
                            {this.state.dashboard == []
                              ? 0
                              : this.state.dashboard.customer}
                            789
                          </span>
                        </span>
                        <span class="weight-500 uppercase-font txt-light block font-13">
                          Surveyors
                        </span>
                      </div>
                      <div
                        class="col-xs-6 text-center pa-0 pl-0 pr-0 data-wrap-right"
                        style={{ color: "#4D85BD" }}
                      >
                        <img
                          src={user}
                          style={{ width: 90, height: 90 }}
                          class="zmdi data-right-rep-icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="panel panel-default card-view pa-0 col-lg-6 col-md-6 col-sm-6 col-xs-12"
            style={{ borderRadius: 10, marginLeft: "5%" }}
          >
            <div class="panel-wrapper collapse in">
              <div class="panel-body pa-0">
                <div class="sm-data-box " style={{ borderRadius: 10 }}>
                  <div class="container-fluid">
                    <div class="row">
                      <div
                        class="col-xs-6 text-center pl-0 pr-0 data-wrap-left"
                        style={{ borderRadius: 10, background: "#7CAA2D" }}
                      >
                        <span class="txt-light block counter">
                          <span class="counter-anim">
                            {this.state.dashboard == []
                              ? 0
                              : this.state.dashboard.customer}
                            789
                          </span>
                        </span>
                        <span class="weight-500 uppercase-font txt-light block font-13">
                          Vendors
                        </span>
                      </div>
                      <div
                        class="col-xs-6 text-center pa-0 pl-0 pr-0 data-wrap-right"
                        style={{ color: "#4D85BD" }}
                      >
                        <img
                          src={survey}
                          style={{ width: 90, height: 90 }}
                          class="zmdi data-right-rep-icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="panel panel-default card-view pa-0 col-lg-4 col-md-6 col-sm-6 col-xs-12"
            style={{ borderRadius: 10, marginLeft: "5%", marginRight: "5%" }}
          >
            <div class="panel-wrapper collapse in">
              <div class="panel-body pa-0">
                <div
                  class="sm-data-box "
                  style={{ borderRadius: 10, background: "#fff" }}
                >
                  <div class="container-fluid">
                    <div class="row">
                      <div
                        class="col-xs-6 text-center pl-0 pr-0 data-wrap-left"
                        style={{ borderRadius: 10, background: "#CB6318" }}
                      >
                        <span class="txt-light block counter">
                          <span class="counter-anim">
                            {this.state.dashboard == []
                              ? 0
                              : this.state.dashboard.customer}
                            789
                          </span>
                        </span>
                        <span class="weight-500 uppercase-font txt-light block font-13">
                          Payments
                        </span>
                      </div>
                      <div
                        class="col-xs-6 text-center pa-0 pl-0 pr-0 data-wrap-right"
                        style={{ color: "#4D85BD" }}
                      >
                        <img
                          src={payment}
                          style={{ width: 90, height: 90 }}
                          class="zmdi data-right-rep-icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          style={{
            flex: 1,
            background: "#D0E1F9",
            display: "flex",
            padding: 30,
          }}
        >
          <div style={{ flex: 2 }}>
            <TableCard
              data={this.state.transaction_data}
              sq_data={this.state.sq_data}
              update={() => this.load_home()}
            />
          </div>
        </div> */}
      </div>
    );
  }
}
