const api_link = "http://bnp.shreeramtech.com/api/admin_api.php";
const access_key = "43e224a0efdda45db6ba7bf3b335ab20";

export default class BackendService {
  login_admin(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencode',
      },
      body: JSON.stringify({
        data: data,
        login_admin: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_surveyors(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencode',
      },
      body: JSON.stringify({
        data: data,
        load_surveyors: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_surveyor(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencode',
      },
      body: JSON.stringify({
        data: data,
        add_surveyor: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_surveyor_data(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencode',
      },
      body: JSON.stringify({
        data: data,
        load_surveyor_data: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  chng_surveyor_status(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencode',
      },
      body: JSON.stringify({
        data: data,
        chng_surveyor_status: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_vendors(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencode',
      },
      body: JSON.stringify({
        data: data,
        load_vendor: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_vendor_data(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencode',
      },
      body: JSON.stringify({
        data: data,
        load_vendor_data: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_payment(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencode',
      },
      body: JSON.stringify({
        data: data,
        load_payment: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }
}
